import React from "react";
// third-party
import { FormattedMessage } from 'react-intl';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faCartShopping,
    faUserShield,
    faBookBookmark,
    faGift,
    faClipboardList,
    faQuestion,
    faHeadset
} from "@fortawesome/free-solid-svg-icons";


//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/',
            icon: <FontAwesomeIcon icon={faHouse} style={{height: '1.2em'}} />,
            breadcrumbs: false,
            external: true
        },
        {
            id: 'sales',
            title: <FormattedMessage id="sales" />,
            type: 'item',
            url: '/#sales',
            icon: <FontAwesomeIcon icon={faGift} style={{height: '1.2em'}} />,
            breadcrumbs: false,
            external: true
        },
        {
            id: 'services',
            title: <FormattedMessage id="services" />,
            type: 'item',
            url: '/#services',
            icon: <FontAwesomeIcon icon={faCartShopping} style={{height: '1.2em'}} />,
            breadcrumbs: false,
            external: true
        },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            url: '/orders',
            icon: <FontAwesomeIcon icon={faClipboardList} style={{height: '1.2em'}} />,
            breadcrumbs: true,
        },
        {
            id: 'privacy-policy',
            title: <FormattedMessage id="privacy-policy" />,
            type: 'item',
            url: '/privacy_policy',
            icon: <FontAwesomeIcon icon={faUserShield} style={{height: '1.2em'}} />,
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'terms',
            title: <FormattedMessage id="terms" />,
            type: 'item',
            url: '/terms',
            icon: <FontAwesomeIcon icon={faBookBookmark} style={{height: '1.2em'}} />,
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'faq',
            title: <FormattedMessage id="faq" />,
            type: 'item',
            url: '/faq',
            icon: <FontAwesomeIcon icon={faQuestion} style={{height: '1.2em'}} />,
            breadcrumbs: true,
            // target: true
        },
        {
            id: 'contacts',
            title: <FormattedMessage id="contacts" />,
            type: 'item',
            url: '/contacts',
            icon: <FontAwesomeIcon icon={faHeadset} style={{height: '1.2em'}} />,
            breadcrumbs: true,
        },

        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            // caption: <FormattedMessage id="orders" />,
            type: 'collapse',
            hide: true,
            url: '/orders',
            children: [
                {
                    id: 'wait',
                    title: <FormattedMessage id="wait" />,
                    type: 'item',
                    url: '/wait',
                    breadcrumbs: true,
                    hide: true,
                },
                {
                    id: 'error',
                    title: <FormattedMessage id="error" />,
                    type: 'item',
                    url: '/error',
                    breadcrumbs: true,
                    hide: true,
                },
                {
                    id: 'success',
                    title: <FormattedMessage id="success" />,
                    type: 'item',
                    url: '/success',
                    breadcrumbs: true,
                    hide: true,
                }
            ]
        },



        // {
        //     id: 'documentation',
        //     title: <FormattedMessage id="documentation" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons['IconHelp'],
        //     external: true,
        //     target: true
        // },
        // {
        //     id: 'roadmap',
        //     title: <FormattedMessage id="roadmap" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/roadmap',
        //     icon: icons['IconSitemap'],
        //     external: true,
        //     target: true
        // }
    ]
};
