import React from 'react';

import LogoInstaBoost from '../assets/images/logo-insta-boost.top.png';
import LogoHideFlow from '../assets/images/logo-hideflow.pl.png';

const logos = {
    'insta-boost.top': LogoInstaBoost,
    'hideflow.pl': LogoHideFlow
};

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    // const theme = useTheme();

    return (
      /*<img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />*/
      <img src={logos[process.env.REACT_APP_NAME]} alt={`Go to home ${process.env.REACT_APP_NAME}`} width="220" />
    );
};

export default Logo;
